<template>
    <button v-if="isPreviewEnabled"
        type="button"
        class="fixed bottom-0 right-0 z-40 rounded-tl bg-black/70 px-4 py-2 text-xl text-white"
        @click="exitPreview">
        Exit preview mode
    </button>
</template>

<script setup lang="ts">
const { isPreviewEnabled, setPreviewState } = useElevate();

const route = useRoute();

if (route.query.PREVIEW !== undefined) {
    setPreviewState(route.query.PREVIEW === 'true');
}

async function exitPreview() {
    setPreviewState(false);
}
</script>
