<template>
    <transition
        enter-active-class="transition-opacity duration-700"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-700"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="(contents?.content.visibility || false)
                && isPopupVisible && !isPopupClosed && contents?.content !== undefined"
            class="fixed top-0 z-50 h-screen w-screen bg-black/50">
            <div
                class="fixed left-1/2 top-1/2 z-50
                -translate-x-1/2 -translate-y-1/2 rounded-xs bg-gray-100
                [filter:drop-shadow(-3px_7px_7px_rgb(0_0_0/80%))] md:h-1/3 md:w-2/3 lg:w-1/2 xl:w-1/2
                2xl:w-1/3">
                <button type="button"
                    class="absolute -right-2 -top-2 z-20 size-8 rounded-full bg-red-500 text-white"
                    @click="close">
                    <v-icon icon="close" />
                </button>

                <!-- Section for background image -->
                <div class="absolute inset-0">
                    <nuxt-img :src="contents?.content.background_image.filename"
                        :alt="contents?.content.background_image.alt"
                        :width="contents?.content.background_image.width"
                        :height="contents?.content.background_image.height"
                        provider="storyblok"
                        loading="lazy"
                        class="size-full rounded-xs object-cover" />
                </div>

                <div class="relative flex h-full flex-col items-center justify-center space-y-md p-md">
                    <!-- Section for popup content -->
                    <div class="space-y-md px-md text-center text-white
                        [text-shadow:0_4px_3px_rgb(0_0_0/20%),0_2px_2px_rgb(0_0_0/20%)]">
                        <v-h3 tag="h2">
                            {{ contents?.content.title }}
                        </v-h3>

                        <p class="font-prenton-cond text-5xl uppercase md:leading-8">
                            {{ contents?.content.ingress }}
                        </p>
                    </div>

                    <!-- Section for newsletter input -->
                    <template v-if="contents?.content.button_type === 'newsletter'">
                        <!-- Email is revealed after button has been clicked -->
                        <form v-if="isFormVisible"
                            class="relative"
                            @submit.prevent="send">
                            <label for="email"
                                class="hidden">
                                {{ t('email') }}
                            </label>

                            <input id="email"
                                v-model="emailInput"
                                type="email"
                                :placeholder="`${t('email')}`"
                                class="h-12 w-full rounded-xl px-md pr-xl text-black">

                            <button type="submit"
                                aria-label="Submit"
                                class="absolute right-1 top-1 flex size-10 items-center justify-center rounded-lg border
                                    border-solid border-red-500 bg-red-500 p-1 text-white">
                                <v-icon icon="send"
                                    class="text-2xl" />
                            </button>
                        </form>

                        <v-button v-else
                            size="wide"
                            theme="gray"
                            @click="revealForm">
                            {{ contents?.content.button_title }}
                        </v-button>
                    </template>

                    <!-- Section for redirect link -->
                    <nuxt-link v-if="contents?.content.button_type === 'redirect'"
                        :to="formatSbUrl(contents?.content.button_link?.cached_url)"
                        class="mx-auto">
                        <v-button size="wide"
                            theme="gray"
                            @click="redirect">
                            {{ contents?.content.button_title }}
                        </v-button>
                    </nuxt-link>

                    <!-- Section for promotion code input -->
                    <div v-if="contents?.content.button_type === 'promotion'"
                        class="">
                        <form v-if="isFormVisible"
                            class="relative"
                            @submit.prevent="applyPromotionCode">
                            <label for="promotionCode"
                                class="hidden pl-md text-white
                                [text-shadow:0_4px_3px_rgb(0_0_0/20%),0_2px_2px_rgb(0_0_0/20%)]">
                                {{ t('promotion-code-label') }}
                            </label>

                            <input id="promotionCode"
                                v-model="promotionCodeInput"
                                type="text"
                                name="promotionCode"
                                :placeholder="`${t('promotion-code')}`"
                                class="h-12 w-full rounded-xl px-md pr-xl text-black">

                            <button type="submit"
                                aria-label="Submit"
                                class="absolute right-1 top-1 flex size-10 items-center justify-center rounded-lg border
                                    border-solid border-red-500 bg-red-500 p-1 text-white">
                                <v-icon icon="send"
                                    class="text-2xl" />
                            </button>
                        </form>

                        <v-button v-else
                            size="wide"
                            theme="gray"
                            @click="revealForm">
                            {{ contents?.content.button_title }}
                        </v-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { whenever } from '@vueuse/core';
import { addMonths } from 'date-fns';
import { useToast } from '~~/store/toast';

const { t } = useI18n();
const { csrf } = useCsrf();
const toast = useToast();
const cart = useCart();

const { format: formatSbUrl } = useSbUrl();

const isPopupClosed = useCookie<boolean>('popup.isPopupClosed', {
    expires: addMonths(new Date(), 1),
});

const emailInput = ref<string>('');
const promotionCodeInput = ref<string>('');
const isFormVisible = ref(false);
const isPopupVisible = ref(false);
const consentIsSet = ref(!!useCookie('cc_cookie').value);

whenever(consentIsSet, () => {
    setTimeout(() => {
        isPopupVisible.value = true;
    }, 3000);
}, { immediate: true });

const { onConsentCategoryChange } = useCookieFirst();

onConsentCategoryChange(() => {
    consentIsSet.value = true;
});

const contents = useStoryblokStory('global/email-popup');

function close() {
    isPopupVisible.value = false;
    isPopupClosed.value = true;
}

function redirect() {
    isPopupVisible.value = false;
}

function revealForm() {
    isFormVisible.value = true;
}

async function send() {
    if (emailInput.value.length === 0) {
        return;
    }

    await useFetch('/newsletter', {
        method: 'POST',
        body: {
            email: emailInput.value,
        },
        headers: {
            ...csrf ? {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'csrf-token': csrf,
            } : {},
        },
    });

    toast.add('success', contents.value?.content.success_message || '');

    close();

    emailInput.value = '';
}

async function applyPromotionCode() {
    if (!promotionCodeInput.value) {
        toast.add('warning', `${t('toast.popup-no-code')}`);
        return;
    }

    try {
        await cart.setPromotionCode(promotionCodeInput.value);

        toast.add('success', contents.value?.content.success_message || '');

        close();
    } catch {
        toast.add('warning', `${t('toast.popup-warning')}`);
    }
}
</script>

<i18n lang="json">
{
    "et": {
        "email": "E-mail",
        "promotion-code": "Sooduskood",
        "promotion-code-label": ""
    },
    "fi": {
        "email": "Sähköposti",
        "promotion-code": "Kampanjakoodi",
        "promotion-code-label": "Syötä kampanjakoodi ja alennuksesi on heti käytössä kassalla!"
    }
}
</i18n>
